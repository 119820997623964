import {baseApi} from "./apiService";

export const blogApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            createBlog: builder.mutation({
                query(data) {
                    return {
                        url: `blogs`,
                        method: 'POST',
                        body: data,
                    }
                },
            }),
            getBlogs: builder.query({
                query: () => `blogs`,
                providesTags: (result) =>
                    result
                        ? [
                            {type: 'Blogs', id: result.id},
                            {type: 'Blogs', id: 'LIST'},
                        ] : [
                            {type: 'Blogs', id: 'LIST'},
                        ]
            }),
        }),
    }
)

export const {
    useCreateBlogMutation,
    useGetBlogsQuery,
} = blogApi
