import {
    Box, Center, Heading, HStack, Spacer,
    Table,
    TableContainer, Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr, useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGetCurrentUserQuery, useGetUsersListQuery} from "../../services/userService";
import {Role, User} from "../../types";


const UsersTable = () => {
    const {t} = useTranslation()
    const {data, isLoading} = useGetUsersListQuery(undefined)
    const {data: currentUser} = useGetCurrentUserQuery(undefined)
    const tealColor = useColorModeValue('teal.50', 'teal.900')
    const stripedColor = useColorModeValue('gray.50', 'gray.700')

    if (isLoading) {
        return (
            <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                <Center mb={2}>
                    <HStack spacing={4}>
                        <Heading size={"sm"}>{t('common.user', {count: 2})}</Heading>
                    </HStack>
                    <Spacer/>
                </Center>
                {/*todo late pagination and sort*/}
                <TableContainer w={"full"}>
                    <Table variant="striped">
                        <Thead>
                            <Tr sx={{th: {color: "black"}}}>
                                <Th>{t('users.name')} </Th>
                                <Th>{t('users.role')} </Th>
                            </Tr>
                        </Thead>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <Box py={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center px={8} mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('common.user', {count: 2})}</Heading>
                </HStack>
                <Spacer/>
            </Center>
            {/*todo late pagination and sort*/}
            <TableContainer w={"full"}>
                <Table variant="simple">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            <Th>{t('users.name')} </Th>
                            <Th>{t('users.role')} </Th>
                            {/*<Th>{t('admin.users.activeCompany')} </Th>*/}
                            {/*<Th>{t('common.createdAt')}</Th>*/}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((user: User, index: number) => {
                            const isCurrentUser = user.id === currentUser?.id
                            const bgColor = isCurrentUser ? tealColor : (index % 2 === 0 ? 'transparent' : stripedColor)

                            return (
                                <Tr
                                    key={user.id + index}
                                    bg={bgColor}
                                    sx={{
                                        td: {textAlign: 'center', color: isCurrentUser ? 'teal.800' : 'gray.700'},
                                    }}
                                >
                                    <Td>{user.first_name} {user.last_name}</Td>
                                    {/*<Td w={"full"}>{user.company.name}</Td>*/}
                                    <Td>
                                        {user.roles
                                            .filter(role => Object.values(Role).includes(role))
                                            .map(role => <Tag size={'md'} variant='solid' colorScheme='teal'>
                                                {t(`users.roles.${role}`)}
                                            </Tag>)}
                                    </Td>
                                    {/*<Td w={"full"}><Button*/}
                                    {/*    onClick={() => getToken(user.id)}>{t('admin.users.impersonate')}</Button></Td>*/}
                                    {/*<FilesTableCell*/}
                                    {/*    content={user.created_at}*/}
                                    {/*    type={CellTypes.date}*/}
                                    {/*/>*/}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default UsersTable;
