import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {userApi} from "../services/userService";
import {userAdminApi} from "../services/admin/userAdminService";
import {RootState} from "../store/store";

interface Token {
    token: string;
    refresh_token: string;
}

interface AuthState {
    token: string | null;
    refreshToken: string | null;
}

const token: Token | null = JSON.parse(localStorage.getItem('tokens') as string);

const authenticationEmptyState: AuthState = {
    token: null,
    refreshToken: null,
};

const authenticationInitialState: AuthState = token ? {
    token: token.token,
    refreshToken: token.refresh_token,
} : {...authenticationEmptyState};

const authSlice = createSlice({
    name: 'auth',
    initialState: authenticationInitialState,
    reducers: {
        setTokens: (
            state,
            {payload: {token, refresh_token}}: PayloadAction<Token>
        ) => {
            localStorage.setItem('tokens', JSON.stringify({token: token, refresh_token: refresh_token}))
            state.token = token
            state.refreshToken = refresh_token
        },
        logout: () => {
            localStorage.removeItem('tokens')
            window.location.reload();
            return authenticationEmptyState
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApi.endpoints.login.matchFulfilled,
                (state, {payload}: PayloadAction<Token>) => {
                localStorage.setItem('tokens', JSON.stringify({
                    token: payload.token,
                    refresh_token: payload.refresh_token
                }))
                state.token = payload.token
                state.refreshToken = payload.refresh_token
            },
        )
        builder.addMatcher(
            userAdminApi.endpoints.getImpersonateToken.matchFulfilled,
            (state, {payload}: PayloadAction<Token>) => {
                localStorage.setItem('tokens', JSON.stringify({
                    token: payload.token,
                    refresh_token: payload.refresh_token
                }))
                state.token = payload.token
                state.refreshToken = payload.refresh_token
                window.location.reload();
            }
        )
        //todo add this when created to user slice
        // builder.addCase(authActions.logout,(state)=>initialState)
    },
})

export const {logout, setTokens} = authSlice.actions

export const isLoggedIn = (state: RootState): boolean => state.auth.token !== null;

// export const selectCurrentUser = (state) => state.auth.user
export const selectAuth = (state: RootState): AuthState => state.auth;

export default authSlice.reducer

