import {store} from "../store/store";
import {Dispatch, isRejectedWithValue, MiddlewareAPI, UnknownAction} from "@reduxjs/toolkit";
import {accessDeniedToast} from "../slices/toastSlice";

export const rtkQueryNotifications = (api: MiddlewareAPI) => (next: Dispatch<UnknownAction>) => (action: UnknownAction) => {

    if (!['api/executeQuery/rejected', 'api/executeMutation/rejected'].includes(action.type)) {
        return next(action)
    }

    if (isRejectedWithValue(action)) {
        // @ts-ignore
        console.log(action.payload.originalStatus)
        // @ts-ignore
        if (action.payload.originalStatus === 403) {
            store.dispatch(accessDeniedToast())
        }
        // //todo add array of actions if they should be excluded from 5XX errors
        // if (![401, 404].includes(action.payload.originalStatus)) {
        //     store.dispatch(errorAlert());
        // }
    }

    return next(action)
}
