import React, {useState, useEffect, useContext, useRef, memo} from 'react';
import {Box, Text, useColorModeValue, Image, IconButton, useToast} from '@chakra-ui/react';
// @ts-ignore
import {PDFDocument} from 'pdf-lib';
import {
    draggable,
    dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {combine} from '@atlaskit/pragmatic-drag-and-drop/combine';
import * as pdfjsLib from 'pdfjs-dist';
import {InstanceIdContext} from "./DisplayFiles";
import invariant from "tiny-invariant";
import {IoTrashSharp} from "react-icons/io5";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks/store";
import {isLoggedIn} from "../../slices/authSlice";

interface PDFOrganizerProps {
    blob: Blob;
    index: number;
    onRemove: (index: number) => void;
    onEncryptedFile: (index: number) => void;
}

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
type State = 'idle' | 'dragging' | 'over';

const ThumbnailViewer = memo(function ThumbnailViewer({blob, index, onRemove, onEncryptedFile}: PDFOrganizerProps) {
    const {t} = useTranslation();
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const [thumbnail, setThumbnail] = useState<string>('');
    const [pageCount, setPageCount] = useState<number>(1);
    const [isEncrypted, setIsEncrypted] = useState<boolean>(false);
    const isLogged = useAppSelector(isLoggedIn);
    const instanceId = useContext(InstanceIdContext);
    const [state, setState] = useState<State>('idle');
    const ref = useRef<HTMLImageElement | null>(null);
    const toast = useToast();

    useEffect(() => {
        const createThumbnail = async () => {
            const arrayBuffer = await blob.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer, {ignoreEncryption: true});
            const pdfData = await pdfjsLib.getDocument(arrayBuffer).promise;
            setPageCount(pdfDoc.getPageCount());
            setIsEncrypted(pdfDoc.isEncrypted);

            if (pdfDoc.isEncrypted && !isLogged) {
                onEncryptedFile(index);
                toast({
                    title: t('pdfMerger.encryptedFile'),
                    description: t('pdfMerger.loginToView'),
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            const page = await pdfData.getPage(1);
            const viewport = page.getViewport({scale: 0.5});
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // @ts-ignore
            await page.render({canvasContext: context, viewport: viewport}).promise;

            setThumbnail(canvas.toDataURL());
        };

        createThumbnail();
    }, [blob]);

    useEffect(() => {
        const el = ref.current;
        invariant(el);

        return combine(
            draggable({
                element: el,
                getInitialData: () => {
                    return {type: 'grid-item', blob, instanceId};
                },
                onDragStart: () => setState('dragging'),
                onDrop: () => setState('idle'),
            }),
            dropTargetForElements({
                element: el,
                getData: () => ({blob}),
                getIsSticky: () => true,
                canDrop: ({source}) => {
                    return source.data.instanceId === instanceId && source.data.type === 'grid-item' && source.data.blob !== blob;
                },
                onDragEnter: () => setState('over'),
                onDragLeave: () => setState('idle'),
                onDrop: () => setState('idle'),
            }),
        );
    }, [instanceId, blob]);

    const boxStyle = {
        transform: state === 'over' ? 'scale(1.05)' : state === 'dragging' ? 'scale(0.95)' : 'scale(1)',
        opacity: state === 'dragging' ? 0.5 : 1,
        boxShadow: state === 'over' ? 'xl' : 'md',
        borderColor: state === 'over' ? 'teal.500' : borderColor,
        borderWidth: state === 'over' ? '2px' : '1px',
    };

    return (
        <Box
            ref={ref}
            m={2}
            borderRadius="md"
            overflow="hidden"
            bg={bgColor}
            transition="all 0.2s"
            width="200px"
            height="280px"
            position="relative"
            _hover={{
                transform: 'scale(1.01)',
                boxShadow: 'lg',
                cursor: 'grab',
            }}
            {...boxStyle}
        >
            {thumbnail ? (
                <Image
                    draggable={false}
                    src={thumbnail}
                    alt={t('pdfMerger.thumbnailAlt')}
                    objectFit="contain"
                    w="100%"
                    h="100%"
                />
            ) : (
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="gray.200"
                >
                    <Text>{isEncrypted ? t('pdfMerger.encrypted') : t('pdfMerger.loading')}</Text>
                </Box>
            )}
            <IconButton
                variant='ghost'
                colorScheme='red'
                aria-label={t('pdfMerger.removePDF')}
                onClick={() => onRemove(index)}
                position="absolute"
                bottom={1}
                right={2}
                px={2}
                py={1}
                icon={<IoTrashSharp/>}
                draggable={false}
            />
            <Text
                position="absolute"
                top={2}
                right={2}
                bg="rgba(0,0,0,0.5)"
                color="white"
                px={2}
                py={1}
                borderRadius="md"
                fontSize="xs"
                draggable={false}
            >
                {isEncrypted ? t('pdfMerger.encrypted') : t('pdfMerger.pageCount', {count: pageCount})}
            </Text>
        </Box>
    );
});

export default ThumbnailViewer;
