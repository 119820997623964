import {useTranslation} from "react-i18next";
import React, {ReactNode} from "react";
import {isFilled} from "../../utils/generalHelper";
import {
    Center, HStack,
    Stack,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import Moment from "react-moment";
import {BsFillFileEarmarkXFill} from "react-icons/bs";
import {CellTypes} from "../../types";
import {IoHandLeftOutline} from "react-icons/io5";


export default function TableCell(
    {
        content,
        subContent,
        placeholder = '',
        shorten = false,
        maxLength = 10,
        isContentFilled = true,
        type = CellTypes.Regular,
    }:
        {
            content: string | null
            subContent?: string | null
            placeholder?: string
            shorten?: boolean
            maxLength?: number
            isContentFilled?: boolean
            type?: CellTypes
        }
) {
    const {t, i18n} = useTranslation();

    const shortenCombinedId = () => {
        if (isFilled(content) && isFilled(subContent)) {
            return <Text>
                {`${content.length > Math.round((maxLength) / 2 + 2)
                    ? content.substring(0, Math.round((maxLength) / 2)) + '...'
                    : content
                } / ${subContent.length > Math.round((maxLength) / 2 + 2)
                    ? subContent.substring(0, Math.round((maxLength) / 2)) + '...'
                    : subContent
                }`}
            </Text>
        }
        if (isFilled(content) && !isFilled(subContent)) {
            return <Text>
                {`${content.length > Math.round((maxLength) / 2 + 2)
                    ? content.substring(0, Math.round((maxLength) / 2)) + '...'
                    : content
                } / - `}
            </Text>
        }
        if (!isFilled(content) && isFilled(subContent)) {
            return <Text>
                {` - / ${subContent.length > Math.round((maxLength) / 2 + 2)
                    ? subContent.substring(0, Math.round((maxLength) / 2)) + '...'
                    : subContent
                }`}
            </Text>
        }
    }


    const cell = (): ReactNode | string | null => {
        switch (type) {
            case CellTypes.Regular:
                if (!isFilled(content)) {
                    return '';
                }
                return shorten && content.length > maxLength
                    ? <Tooltip hasArrow label={content}>
                        {content.substring(0, maxLength) + '...'}
                    </Tooltip>
                    : content
            case CellTypes.Date: {
                return isFilled(content) ?
                    <Moment format={'MMM D, YYYY'} locale={i18n.language}>{content}</Moment> : null;
            }
            case CellTypes.CombinedIds: {
                return isContentFilled
                    ? <Tooltip hasArrow label={<Stack>
                        <Text>{`${t('files.view.internalID')}: ${content ?? ''}`}</Text>
                        <Text>{`${t('files.view.externalID')}: ${subContent ?? ''}`}</Text>
                    </Stack>}>
                        <Text>{shortenCombinedId()}</Text>
                    </Tooltip>
                    : placeholder.length > maxLength ? <Tooltip hasArrow label={placeholder}>
                            <Text>{placeholder.substring(0, maxLength) + '...'}</Text>
                        </Tooltip>
                        : placeholder
            }
            case CellTypes.Name: {
                return isContentFilled
                    ? shorten ? <Tooltip hasArrow label={`${content ?? ''} ${subContent ?? ''}`}>
                            <Text>{content}</Text>
                        </Tooltip>
                        : `${content ?? ''} ${subContent ?? ''}`
                    : null
            }
            case CellTypes.NoAccess: {
                if (!isFilled(content)) {
                    return '';
                }

                return shorten && content.length > maxLength
                    ? <Tooltip hasArrow label={content}>
                        <HStack>
                            <IoHandLeftOutline color="var(--chakra-colors-red-500)"/>
                            <Text>{content.substring(0, maxLength) + '...'}</Text>
                        </HStack>
                    </Tooltip>
                    : content
            }
            default:
                if (!isFilled(content)) {
                    return '';
                }
                return shorten && content.length > maxLength
                    ? <Tooltip hasArrow label={content}>
                        {content.substring(0, maxLength) + '...'}
                    </Tooltip>
                    : content
        }
    };

    return (
        <Center gap={2}>
            {!isContentFilled
                ? <BsFillFileEarmarkXFill
                    color={'var(--chakra-colors-red-400)'}
                    fontSize={20}
                    // color="var(--chakra-colors-teal-400)"
                />
                : null
            }
            {cell()}
        </Center>
    );
};
