import React, {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import {Alert, AlertIcon, Box, Flex, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import {monitorForElements,} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import ThumbnailViewer from "./ThumbnailViewer";

export const InstanceIdContext = createContext<symbol | null>(null);

export default function DisplayFiles(
    {
        blobs,
        setBlobs,
        onRemove,
        onEncryptedFile,
    }: {
        blobs: Blob[],
        setBlobs: React.Dispatch<React.SetStateAction<Blob[]>>;
        onRemove: (index: number) => void,
        onEncryptedFile: (index: number) => void;
    }
) {
    const {t} = useTranslation()
    const [instanceId] = useState(() => Symbol('instance-id'));
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    // const borderColor = useColorModeValue('teal.200', 'teal.600');

    useEffect(() => {
        return monitorForElements({
            canMonitor({source}) {
                return source.data.instanceId === instanceId;
            },
            onDrop({source, location}) {
                const destination = location.current.dropTargets[0];
                if (!destination) {
                    return;
                }
                const destinationBlob = destination.data.blob;
                const startBlob = source.data.blob;

                if (!(destinationBlob instanceof Blob) || !(startBlob instanceof Blob)) {
                    return;
                }

                const updated = [...blobs];
                const startIndex = blobs.indexOf(startBlob);
                const destIndex = blobs.indexOf(destinationBlob);
                updated[startIndex] = destinationBlob;
                updated[destIndex] = startBlob;

                setBlobs(updated);
            },
        });
    }, [instanceId, blobs, setBlobs]);

    if (!blobs || blobs.length === 0) {
        return <Text>{t('pdfMerger.noFilesUploaded')}</Text>;
    }

    return (
        <InstanceIdContext.Provider value={instanceId}>
            <VStack spacing={6} align="stretch" p={4}>
                {/*<HStack spacing={2} p={2} bg={'white'} borderRadius="md" boxShadow="sm">*/}
                {/*    /!*<Button leftIcon={<AddIcon />}>Add</Button>*!/*/}
                {/*    <Button>Add</Button>*/}
                {/*    <IconButton aria-label="Rotate Left"/>*/}
                {/*    /!*<IconButton aria-label="Rotate Left" icon={<ArrowLeftIcon />} />*!/*/}
                {/*    <IconButton aria-label="Rotate Right"/>*/}
                {/*    /!*<IconButton aria-label="Rotate Right" icon={<ArrowRightIcon />} />*!/*/}
                {/*    <Flex align="center">*/}
                {/*        <Text mr={2}>Show all pages</Text>*/}
                {/*        /!*<Switch isChecked={showAllPages} onChange={() => setShowAllPages(!showAllPages)}/>*!/*/}
                {/*    </Flex>*/}
                {/*    <Button colorScheme="yellow" size="sm">Pro</Button>*/}
                {/*</HStack>*/}
                <Alert status='info'>
                    <AlertIcon/>
                    {t('pdfMerger.dragAndDropInstruction')}
                </Alert>
                <Text fontSize="lg" textAlign="center" color={textColor} mb={4}>
                </Text>
                <Flex
                    flexWrap="wrap"
                    justifyContent="center"
                    bg={bgColor}
                    borderRadius="lg"
                    p={4}
                    minHeight="300px"
                >
                    <Box display="grid" gridTemplateColumns="repeat(3, 200px)" gap="4">
                        {blobs.map((blob, index) => (
                            <ThumbnailViewer
                                key={index}
                                index={index}
                                onRemove={onRemove}
                                blob={blob}
                                onEncryptedFile={onEncryptedFile}
                            />
                        ))}
                        {/*<Box*/}
                        {/*    m={2}*/}
                        {/*    borderWidth={2}*/}
                        {/*    borderRadius="md"*/}
                        {/*    borderColor={borderColor}*/}
                        {/*    borderStyle="dashed"*/}
                        {/*    width="200px"*/}
                        {/*    height="280px"*/}
                        {/*    display="flex"*/}
                        {/*    flexDirection="column"*/}
                        {/*    justifyContent="center"*/}
                        {/*    alignItems="center"*/}
                        {/*    cursor="pointer"*/}
                        {/*    _hover={{bg: 'gray.50'}}*/}
                        {/*>*/}
                        {/*    /!*<AddIcon boxSize={8} color="gray.400" />*!/*/}
                        {/*    <Text mt={2} fontSize="sm" color="gray.500" textAlign="center">*/}
                        {/*        Add PDF, image, Word, Excel, and PowerPoint files*/}
                        {/*    </Text>*/}
                        {/*</Box>*/}
                    </Box>
                </Flex>
            </VStack>
        </InstanceIdContext.Provider>
    );
}
