import React, {useState, useEffect} from 'react';
import {Box, Text, VStack, Image, Progress, Button, Link} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import enBanner1 from './../../assets/promo_banners/en-banner-1.png';
// @ts-ignore
import enBanner2 from './../../assets/promo_banners/en-banner-2.png';
// @ts-ignore
import skBanner1 from './../../assets/promo_banners/sk-banner-1.png';
// @ts-ignore
import skBanner2 from './../../assets/promo_banners/sk-banner-2.png';

interface BannerImage {
    src: string;
    alt: string;
}

const bannerImages = {
    en: [
        {src: enBanner1, alt: "English Banner 1"},
        {src: enBanner2, alt: "English Banner 2"},
    ],
    sk: [
        {src: skBanner1, alt: "Slovak Banner 1"},
        {src: skBanner2, alt: "Slovak Banner 2"},
    ],
};

interface DownloadPromoProps {
    onCountdownComplete: () => void;
    onFinish: () => void;
}

export default function DownloadPromo({onCountdownComplete, onFinish}: DownloadPromoProps) {
    const {t, i18n} = useTranslation();
    const [countdown, setCountdown] = useState(5);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [bannerImage, setBannerImage] = useState<BannerImage | null>(null);

    useEffect(() => {
        const currentLanguage = i18n.language.startsWith('sk') ? 'sk' : 'en';
        const randomIndex = Math.floor(Math.random() * bannerImages[currentLanguage].length);
        setBannerImage(bannerImages[currentLanguage][randomIndex]);
    }, [i18n.language, bannerImages]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCount - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            setIsDownloadReady(true);
            onCountdownComplete();
        }
    }, [countdown, onCountdownComplete]);

    return (
        <Box
            maxWidth="600px"
            width="100%"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="xl"
            bg="white"
        >
            <Link href={'/register'}>
                {bannerImage && (
                    <Image
                        src={bannerImage.src}
                        // alt={bannerImage.alt}
                        alt={t('pdfMerger.promo.bannerAlt')}
                        width="100%"
                        height="200px"
                        objectFit="cover"
                    />
                )}
            </Link>
            <VStack spacing={4} p={6}>
                <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                    {isDownloadReady ? t('pdfMerger.promo.downloadReady') : t('pdfMerger.promo.preparingFile')}
                </Text>
                {!isDownloadReady && (
                    <Progress
                        value={(5 - countdown) * 20}
                        width="100%"
                        colorScheme="teal"
                        height="10px"
                        borderRadius="full"
                    />
                )}
                <Text fontSize="lg">
                    {isDownloadReady
                        ? t('pdfMerger.promo.enjoyDownload')
                        : t('pdfMerger.promo.downloadStarting', {seconds: countdown})}
                </Text>
                <Button
                    colorScheme="teal"
                    size="lg"
                    width="full"
                    onClick={onFinish}
                >
                    {isDownloadReady ? t('pdfMerger.promo.finish') : t('pdfMerger.promo.pleaseWait')}
                </Button>
            </VStack>
        </Box>
    );
}
