import {Role, roleHierarchy} from "../constants/roles";

//todo later if needed add inclusive / exclusive (for example super admin is also user) check if it is only user
export function isSuperAdmin(user) {
    return checkRole(user, Role.SuperAdmin)
}

export function isAdmin(user) {
    return checkRole(user, Role.Admin)
}

export function isOwner(user) {
    return checkRole(user, Role.Owner)
}

export function isManager(user) {
    return checkRole(user, Role.Manager)
}

export function isUser(user) {
    return checkRole(user, Role.User)
}

export function isExternal(user) {
    return checkRole(user, Role.External)
}

export function hasSubscription(subscription) {
    //todo check for number of user (only once)
    //todo check for space
    return subscription !== null && ['trialing', 'active'].includes(subscription?.status)
}

export function hasActiveSubscription(subscription) {
    //todo check for number of user (only once)
    //todo check for space
    return subscription !== null && subscription?.status === 'trialing'
}

export function hasExtraStorage(subscription) {
    //todo check for number of user (only once)
    //todo check for space
    return hasSubscription(subscription) && subscription?.purchased_extra_storage
}

export function hasAllowedRole(user, allowedRoles, isStrict = false) {
    if (allowedRoles.length === 0) {
        return true
    }

    if (isStrict) {
        if (Array.isArray(user?.roles)) {
            return user?.roles?.some(role => allowedRoles.includes(role)) ?? false
        }

        return Object.values(user?.roles ?? {})?.some(role => allowedRoles.includes(role)) ?? false
    }

    let userAllowedRoles = [];
    if (Array.isArray(user?.roles)) {
        userAllowedRoles = user?.roles?.flatMap(role => roleHierarchy[role]) ?? []
    } else {
        userAllowedRoles = Object.values(user?.roles ?? {})?.flatMap(role => roleHierarchy[role]) ?? []
    }

    return allowedRoles.some(allowedRole => userAllowedRoles.includes(allowedRole));
}

function checkRole(user, role) {
    if (Array.isArray(user?.roles)) {
        return user?.roles?.includes(role) ?? false
    }

    return Object.values(user?.roles ?? {})?.includes(role) ?? false
}
