import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../store/store";

interface DevLink {
    url: string;
    status: number;
    profiler: string;
}

interface DevState {
    links: DevLink[]
}


const devInitialState: DevState = {
    links: []
}

const devSlice = createSlice({
    name: 'dev',
    initialState: devInitialState,
    reducers: {
        addProfilerLink: (state, {payload}) => {
            console.log(payload)
            state.links = [...state.links, payload]
        },
    },
})

export const {addProfilerLink} = devSlice.actions

export const selectLinks = (state: RootState) => state.dev.links

export default devSlice.reducer

