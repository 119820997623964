import {Dispatch, isRejectedWithValue, MiddlewareAPI, UnknownAction} from "@reduxjs/toolkit";
import {store} from "../store/store";
import {addProfilerLink} from "../slices/dev/devSlice";

export const rtkQueryLogger = (api: MiddlewareAPI) => (next: Dispatch<UnknownAction>) => (action: UnknownAction) => {
    if (process.env.NODE_ENV !== 'development') {
        return next(action)
    }

    if (action?.type?.includes('api/')) {
        if (action?.meta !== undefined) {
            // @ts-ignore
            console.log(action.meta?.arg?.endpointName)
        }

        // @ts-ignore
        const response = action.meta?.baseQueryMeta?.response

        if (response !== undefined && response.headers.get('x-debug-token-link') !== null) {
            store.dispatch(addProfilerLink({
                url: response.url,
                status: response.status,
                profiler: response.headers.get('x-debug-token-link')
            }))
        }
    }

    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!', action)
        // console.warn('We got a rejected action!', alert)
    }

    return next(action)
}
