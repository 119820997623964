import {baseApi} from "./apiService";
import {EmailUploadAddress} from "../types";

export const emailUploadAddressesService = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getEmailUploadAddressesList: builder.query<EmailUploadAddress[], void>({
                query: () => ({
                    url: `email_upload_addresses`,
                }),
                providesTags: (result: EmailUploadAddress[] | undefined) =>
                    result
                        ? [...result.map(({id}) => ({type: 'EmailUploadAddresses' as const, id})), 'EmailUploadAddresses']
                        : ['EmailUploadAddresses'],
            }),
        }),
    }
)

export const {
    useGetEmailUploadAddressesListQuery,
} = emailUploadAddressesService
