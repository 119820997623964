import {
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Text,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useToast,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import UppyPdfUpload from "../components/PdfMerge/UppyPdfUpload";
import {PDFDocument} from 'pdf-lib';
import DisplayFiles from "../components/PdfMerge/DisplayFiles";
import DownloadPromo from "../components/PdfMerge/DownloadPromo";
import {useAppSelector} from "../hooks/store";
import {isLoggedIn} from "../slices/authSlice";

export default function PDFMerge() {
    const {t} = useTranslation();
    const toast = useToast();
    const isLogged = useAppSelector(isLoggedIn);
    const [step, setStep] = useState(1);
    const [fileBlobs, setFileBlobs] = useState<Blob[]>([]);
    const [isPromoOpen, setIsPromoOpen] = useState(false);
    const [mergedPdfBlob, setMergedPdfBlob] = useState<Blob | null>(null);
    const [encryptedFiles, setEncryptedFiles] = useState<number[]>([]);

    const handleNext = (blobs: Blob[]) => {
        setFileBlobs(blobs);
        setStep(2);
    };

    const handleRemove = (index: number) => {
        const newBlobs = fileBlobs.filter((_, i) => i !== index);
        setFileBlobs(newBlobs);
        setEncryptedFiles(encryptedFiles.filter(i => i !== index));
    };

    const handleEncryptedFile = (index: number) => {
        setEncryptedFiles([...encryptedFiles, index]);
    };

    const handleStartOver = () => {
        setFileBlobs([]);
        setEncryptedFiles([]);
        setStep(1);
    };

    const mergePDFs = async () => {
        const mergedPdf = await PDFDocument.create();
        for (const blob of fileBlobs) {
            const pdfData = await blob.arrayBuffer();
            const pdf = await PDFDocument.load(pdfData, {ignoreEncryption: true});
            if (pdf.isEncrypted && !isLogged) {
                throw new Error('Encrypted file detected');
            }
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page);
            });
        }

        const mergedPdfBytes = await mergedPdf.save();
        return new Blob([mergedPdfBytes], {type: 'application/pdf'});
    };

    const handleMergeClick = async () => {
        if (encryptedFiles.length > 0 && !isLogged) {
            toast({
                title: t('pdfMerger.encryptedFilesDetected'),
                description: t('pdfMerger.loginToMerge'),
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsPromoOpen(true);
        try {
            const mergedBlob = await mergePDFs();
            setMergedPdfBlob(mergedBlob);
        } catch (error) {
            console.error("Error merging PDFs:", error);
            toast({
                title: t('pdfMerger.mergeError'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            setIsPromoOpen(false);
        }
    };

    const handleCountdownComplete = () => {
        if (mergedPdfBlob) {
            const url = URL.createObjectURL(mergedPdfBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'filetica-merger.pdf';
            a.click();
            URL.revokeObjectURL(url);
        }
    };

    const handleFinish = () => {
        setIsPromoOpen(false);
        handleStartOver();
    };

    return (
        <Container maxW={"container.xl"} p={8}>
            <HStack mb={2}>
                <Text color={"gray.600"}>{t('nav.freeTools', {count: 2})} / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    {t('pdfMerger.title')}
                </Heading>
            </HStack>
            <Box p={4}>
                {step === 1 && <UppyPdfUpload onNext={handleNext}/>}
                {step === 2 && (
                    <VStack mb={2}>
                        <DisplayFiles
                            blobs={fileBlobs}
                            setBlobs={setFileBlobs}
                            onRemove={handleRemove}
                            onEncryptedFile={handleEncryptedFile}
                        />
                        <Button
                            colorScheme="teal"
                            size="lg"
                            onClick={handleMergeClick}
                            width="full"
                            maxWidth="400px"
                            alignSelf="center"
                            boxShadow="md"
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                            isDisabled={encryptedFiles.length > 0 && !isLogged}
                        >
                            {t('pdfMerger.mergePDFs')}
                        </Button>
                        <Button mt={4} colorScheme="red" onClick={handleStartOver}>
                            {t('pdfMerger.startOver')}
                        </Button>
                    </VStack>
                )}
            </Box>
            <Modal isOpen={isPromoOpen} onClose={() => {
            }} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalBody p={0}>
                        <DownloadPromo
                            onCountdownComplete={handleCountdownComplete}
                            onFinish={handleFinish}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    );
}
