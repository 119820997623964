import React from 'react'
import {Box, Text, Heading, Stack, Grid, GridItem} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useGetEmailUploadByUploadHistoryQuery} from "../../services/emailUpload";
import {FieldConfig, FieldType} from "../../types";
import DisplayField from "../DisplayField";

export default function EmailUploadView({uploadHistoryId}: { uploadHistoryId: string }) {
    const {t} = useTranslation()
    const {data: emailUpload, isLoading, error} = useGetEmailUploadByUploadHistoryQuery({id: uploadHistoryId})

    const fields: FieldConfig[] = [
        {
            title: 'emailUpload.sender',
            field: 'sender_email',
            type: FieldType.Text,
        },
        {
            title: 'emailUpload.subject',
            field: 'subject',
            type: FieldType.Text,
        },
        {
            title: 'emailUpload.receivedAt',
            field: 'received_at',
            type: FieldType.Date,
        },
    ]

    if (isLoading) {
        return <Heading size="sm">{t('common.loading')}</Heading>
    }

    if (error || !emailUpload) {
        return <Heading size="sm" color="red.500">{t('emailUpload.errorLoading')}</Heading>
    }

    return (
        <Stack spacing={4}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                {fields.map((config, index) => (
                    <GridItem key={`email-${index}`}>
                        <DisplayField config={config} data={emailUpload} />
                    </GridItem>
                ))}
                <GridItem>
                    <DisplayField config={{
                        title: 'emailUpload.receiverAddress',
                        field: 'email',
                        type: FieldType.Text,
                    }} data={emailUpload.receiver_address} />
                </GridItem>
            </Grid>
            <Stack>
                <Heading size="xs">{t('emailUpload.content')}</Heading>
                <Box>
                    <Box bg="gray.50" p={3} borderRadius="md">
                        <Text whiteSpace="pre-wrap">{emailUpload.content_txt || emailUpload.content_html}</Text>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    )
}
