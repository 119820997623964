import {
    Box,
    Button,
    Center,
    HStack,
    Heading,
    Modal,
    ModalContent,
    Spacer,
    Stack,
    Text, Skeleton,
} from "@chakra-ui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeFilePreviewModal, isFilePreviewOpen, selectFilePreviewModal} from "../../slices/filePreviewModalSlice";
import {
    useGetPreviewFileQuery,
    useLazyGetDownloadFileQuery,
} from "../../services/fileService";
import {useTranslation} from "react-i18next";

export default function FilePreview() {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const filePreviewModal = useSelector(selectFilePreviewModal)
    const isModalOpen = useSelector(isFilePreviewOpen)

    const {
        data: fileData,
        isError: isFileError,
        isFetching: isLoadingPreview,
    } = useGetPreviewFileQuery({id: filePreviewModal.fileId, name: filePreviewModal.name}, {skip: filePreviewModal.fileId === null})
    const [triggerDownload, {isLoading: isLoadingDownload}] = useLazyGetDownloadFileQuery()

    return (
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeFilePreviewModal())}>
            <ModalContent minW={700}  maxW={1000} p={8} rounded={"md"}>
                <Box>
                    <Stack mb={4}>
                        <Heading size="md">{t('files.preview.title')}</Heading>
                        <Text sx={{mt: "0 !important"}} color={"teal.400"}>
                            {filePreviewModal?.name}
                        </Text>
                    </Stack>

                    <Box overflow={"hidden"}>
                        {isLoadingPreview
                            ? loadingPreview()
                            : isFileError
                                ? <Heading size={'sm'}>{t('files.preview.error')}</Heading>
                                : <Center
                                    sx={{
                                        img: {width: "100%", height: "auto", rounded: "md"},
                                        iframe: {width: "100%", height: "65vh", rounded: "md"}
                                    }}
                                >
                                    {fileData?.type === 'application/pdf'
                                        ? <iframe title={fileData.name} src={fileData?.url}/>
                                        : <img alt={'filename'} src={fileData?.url}/>
                                    }
                                </Center>
                        }
                    </Box>

                    <HStack mt={6}>
                        <Spacer/>
                        <Button variant={"gray"} onClick={() => dispatch(closeFilePreviewModal())}>
                            {t('common.actions.cancel')}
                        </Button>
                        {/*todo add print icon*/}
                        {/*todo add print functionality*/}
                        {/*<Button variant={"gray"}>*/}
                        {/*  Print*/}
                        {/*</Button>*/}
                        <Button
                            isLoading={isLoadingDownload}
                            loadingText='Downloading'
                            variant={"primary"}
                            isDisabled={isFileError}
                            onClick={() => triggerDownload({id: filePreviewModal.fileId, name: filePreviewModal.name})}
                        >{t('common.actions.download')}</Button>
                    </HStack>
                </Box>
            </ModalContent>
        </Modal>
    );
}

function loadingPreview() {
    return (
        <Box rounded={"md"}>
            <Stack minH={450} gap={4} bg={"gray.100"} p={6}>
                <Skeleton height={"80px"} width={"80px"} rounded={"full"}/>
                <Skeleton height={"140px"} rounded={"sm"}/>
                <Stack>
                    <Skeleton height={"1.5"} rounded={"sm"}/>
                    <Skeleton height={"1.5"} rounded={"sm"}/>
                    <Skeleton height={"1.5"} w={"90%"} rounded={"sm"}/>
                </Stack>
            </Stack>
        </Box>
    )
}
