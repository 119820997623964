import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {isFilled} from "../../utils/generalHelper";
import {Button, Center, Flex, Td,} from "@chakra-ui/react";
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table";
import {CellTypes, UploadHistory} from "../../types";
import {AiFillEye} from "react-icons/ai";
import TableWrapper from "../../pages/Table/TableWrapper";
import TableCell from "../../pages/Table/TableCell";
import {IoDocumentText, IoPencil} from "react-icons/io5";
import {showUploadHistoryModal} from "../../slices/uploadHistoryViewModalSlice";
import {fileSourceIcon} from "../../utils/generalHelperElements";

export default function UploadHistoryTable(
    {
        uploadHistory = [],
        hiddenFields = [],
        // inModal = false,
        customAction = undefined,
        customActionArgs = []
    }: {
        uploadHistory: UploadHistory[],
        hiddenFields?: string[],
        // inModal?: boolean,
        customAction?: (info: any, ...args: any[]) => void,
        // customActionArgs?: any[]
        customActionArgs?: any
    }) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // const sorting = useSelector(selectFileRawSorting)

    //todo extract
    const getTranslatedText = (key: string, count: number = 1): string => {
        return t(key, {count: count}) ?? key;
    };

    const columnHelper = createColumnHelper<UploadHistory>()
    const columns = [
            columnHelper.accessor(row => `${row.user.first_name} ${row.user.last_name}`, {
                id: 'createdBy',
                header: getTranslatedText('common.createdBy'),
                cell: info => <TableCell
                    content={info.row.original.user.first_name}
                    subContent={info.row.original.user.last_name}
                    isContentFilled={isFilled(info.row.original.user.first_name)}
                    shorten={true}
                    type={CellTypes.Name}
                />,
                enableSorting: false,
            }),
            columnHelper.accessor('note', {
                id: 'note',
                header: getTranslatedText('common.note'),
                cell: info => <TableCell
                    content={info.getValue()}
                    shorten={true}
                    isContentFilled={true}
                />,
                enableSorting: false,
            }),
            columnHelper.accessor('source', {
                id: 'source',
                header: getTranslatedText('fileSources.source'),
                cell: info => <Center gap={2}>
                    {fileSourceIcon(info.getValue())}
                </Center>,
                enableSorting: false,
            }),
            columnHelper.accessor(row => row.files.length ?? 0, {
                id: 'files',
                header: getTranslatedText('files.file', 2),
                cell: info => <Center gap={2}>
                    <IoDocumentText
                        fontSize={20}
                        color={`var(--chakra-colors-teal-300)`}
                    />
                    {`${info.row.original.files.length ?? 0}`}
                </Center>,
                enableSorting: false,
            }),
            columnHelper.accessor('created_at', {
                id: 'createdAt',
                header: getTranslatedText('files.uploadedAt'),
                cell: info => <TableCell
                    content={info.getValue()}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Date}
                />,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'actions',
                meta: {
                    customTd: true,
                },
                cell: info => customAction
                    ? customAction(info, ...customActionArgs)
                    : <Td px={0} bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                        <Flex justifyContent={"space-around"}>
                            <Button
                                height={'25px'}
                                pe={0}
                                me={0}
                                colorScheme={'white'}
                                onClick={() => navigate(`/upload/${info.row.original.id}`)}
                            >
                                <IoPencil
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                            <Button height={'25px'} ps={0} colorScheme={'white'}
                                    onClick={() => dispatch(showUploadHistoryModal({uploadHistory: info.row.original}))}>
                                <AiFillEye
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                        </Flex>
                    </Td>,
                enableSorting: false,
            }),
        ]
    ;

    const defaultVisibility = {};

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
        return {...defaultVisibility, ...hiddenFields.reduce((acc, field) => ({...acc, [field]: false}), {})};
    });

    const table = useReactTable({
        data: uploadHistory,
        columns,
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        manualSorting: false,
        enableSorting: false,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <TableWrapper table={table} noDataMessage={'uploadHistory.noUploadSesions'}/>
    );
};
