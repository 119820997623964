import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import Uppy from '@uppy/core';
import {Dashboard} from '@uppy/react';
import Slovak from '@uppy/locales/lib/sk_SK';
import English from '@uppy/locales/lib/en_US';
import {Button} from "@chakra-ui/react";


// @ts-ignore
export default function UppyPdfUpload({onNext}) {
    const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    const {i18n, t} = useTranslation()
    const [uppy, setUppy] = useState<Uppy>();
    const [blobs, setBlobs] = useState<Blob[]>([]);


    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['application/pdf'],
            },
            autoProceed: true,
            debug: isDevelopment,
        });

        uppyInstance.on('complete', (result) => {
            const newBlobs = result.successful.map((file) => new Blob([file.data], {type: file.type}));
            setBlobs(newBlobs);
        });

        setUppy(uppyInstance);

        return () => uppyInstance.close();
    }, []);

    useEffect(() => {
        const localeMapping: Record<string, any> = {
            en: English,
            sk: Slovak,
        };

        const currentLocale = localeMapping[i18n.language] || English;
        uppy?.setOptions({
            locale: currentLocale,
        });
    }, [i18n.language, uppy]);

    const handleNext = () => {
        if (blobs.length > 0) {
            onNext(blobs);
        }
    };

    if (!uppy) return null;

    return (
        <>
            <Dashboard
                uppy={uppy}
                width={'100%'}
                showProgressDetails={true}
                proudlyDisplayPoweredByUppy={false}
                hideCancelButton={true}
                hideUploadButton={true}
                hideProgressAfterFinish={true}
            />

            {/*{blobs.length > 0 && <Text mt={4}>Files uploaded successfully!</Text>}*/}
            <Button onClick={handleNext} mt={4} colorScheme="teal" isDisabled={blobs.length === 0}>
                {t('common.actions.next')}
            </Button>
        </>
    );
}
