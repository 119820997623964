import {baseApi} from "./apiService";
import {EmailUpload} from "../types";

export const emailUploadService = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getEmailUploadByUploadHistory: builder.query<EmailUpload, { id: string }>({
                query: ({id}) => ({
                    url: `email_upload/${id}`,
                }),
                providesTags: (result: EmailUpload | undefined) =>
                    result
                        ? [{type: 'EmailUpload', id: result.id}, 'EmailUpload']
                        : ['EmailUpload'],
            }),
        }),
    }
)

export const {
    useGetEmailUploadByUploadHistoryQuery,
} = emailUploadService
