import {
    Button,
    ButtonGroup,
    Center,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {IoShareOutline} from "react-icons/io5";
import {copyToast} from "../../slices/toastSlice";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FieldConfig, FieldType, FileSource, Role} from "../../types";
import {AccessLimit} from "../../utils/AccessLimit";
import {
    closeUploadHistoryViewModal,
    isUploadHistoryViewOpen,
    selectUploadHistoryViewModal
} from "../../slices/uploadHistoryViewModalSlice";
import FilesTable from "../Files/FilesTable";
import DisplayField from "../DisplayField";
import {useNavigate} from "react-router-dom";
import EmailUploadView from "../EmailUpload/EmailUploadView";

export default function UploadHistoryView() {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isModalOpen = useSelector(isUploadHistoryViewOpen)
    const uploadHistoryViewModal = useSelector(selectUploadHistoryViewModal)
    const uploadHistory = uploadHistoryViewModal.uploadHistory

    const fields: FieldConfig[] = [
        {
            title: 'common.ID',
            field: 'id'
        },

        {
            title: 'common.createdBy',
            field: 'user',
            type: FieldType.Profile,
        },
        {
            title: 'common.createdAt',
            field: 'created_at',
            type: FieldType.Date,
        },
        {
            title: 'fileSources.source',
            field: 'source',
            type: FieldType.Source,
        }
    ];

    if (uploadHistory === null) {
        return (
            <Modal isOpen={isModalOpen} onClose={() => dispatch(closeUploadHistoryViewModal())}>
                <ModalOverlay/>
                <ModalContent p={8} maxW={1000} borderRadius={"2xl"}>
                    <Stack gap={4}>
                        <Center mb={4}>
                            <Stack gap={0}>
                                <Heading size={"md"}>{t('uploadHistory.uploadHistory', {count: 1})}</Heading>
                                {/*<Text mt={"0 !important"} color={"teal.400"}>*/}
                                {/*    {file?.name ?? file?.original_name}*/}
                                {/*</Text>*/}
                            </Stack>
                            <Spacer/>
                        </Center>
                    </Stack>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeUploadHistoryViewModal())}>
            <ModalOverlay/>
            <ModalContent p={8} maxW={1000} borderRadius={"2xl"}>
                <Stack gap={4}>
                    <Center mb={4}>
                        <Stack gap={0}>
                            <Heading size={"md"}>{t('uploadHistory.uploadHistory', {count: 1})}</Heading>
                            {/*<Text mt={"0 !important"} color={"teal.400"}>*/}
                            {/*    {file?.name ?? file?.original_name}*/}
                            {/*</Text>*/}
                        </Stack>
                        <Spacer/>
                        <ButtonGroup>
                            <CopyToClipboard text={`${window.location.origin}/uploadHistory/${uploadHistory?.id}`}
                                             onCopy={() => dispatch(copyToast('toast.linkCopy'))}>
                                <Button
                                    bg={"transparent"}
                                    color={"teal.400"}
                                    leftIcon={
                                        <IoShareOutline color="var(--chakra-colors-teal-400)"/>
                                    }
                                >
                                    {t('common.actions.share')}
                                </Button>
                            </CopyToClipboard>
                            <AccessLimit allowedRoles={[Role.User]}>
                                <Button w={"auto"} bg={"transparent"} color={"teal.400"} onClick={() => {
                                    dispatch(closeUploadHistoryViewModal())
                                    navigate(`/upload/${uploadHistory.id}`)
                                }}>
                                    {t('common.actions.edit')}
                                </Button>
                            </AccessLimit>
                        </ButtonGroup>
                    </Center>

                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        {fields.map((config, index) => (
                            <GridItem key={"c" + index}>
                                <DisplayField config={config} data={uploadHistory}/>
                            </GridItem>
                        ))}
                    </Grid>

                    <Stack>
                        <Heading size={"sm"}>{t('common.note', {count: 2})}</Heading>
                        <Text sx={{mt: "4px !important"}}>
                            {uploadHistory?.note}
                        </Text>
                    </Stack>

                    {uploadHistory?.source === FileSource.GlobalEmailUpload || uploadHistory?.source === FileSource.UserEmailUpload
                        ? <AccessLimit allowedRoles={[Role.User]}>
                            <>
                                <HStack gap={2}>
                                    <Heading size={"sm"} color={"gray.400"} whiteSpace="nowrap">
                                      {t('emailUpload.details')}
                                    </Heading>
                                    <Divider/>
                                </HStack>
                                <EmailUploadView uploadHistoryId={uploadHistory.id}/>
                            </>
                        </AccessLimit>
                        : undefined
                    }

                    <AccessLimit allowedRoles={[Role.AgencyClient]}>
                        <>
                            <HStack gap={2}>
                                <Heading size={"sm"} color={"gray.400"}>
                                    {t('files.file', {count: 2})}
                                </Heading>
                                <Divider/>
                            </HStack>
                            <FilesTable files={uploadHistory.files} inModal={true}/>
                        </>
                    </AccessLimit>


                    {/*<Stack>*/}
                    {/*    <Heading size={"sm"}>Comments</Heading>*/}
                    {/*    <Stack>*/}
                    {/*        <HStack gap={2}>*/}
                    {/*            <Avatar size={"sm"} name={"a"} src=""/>*/}
                    {/*            <Stack>*/}
                    {/*                <HStack>*/}
                    {/*                    <Heading size={"sm"}>John smith</Heading>*/}
                    {/*                    <Text mt={"0 !important"} fontSize={14} color={"gray.400"}>*/}
                    {/*                        23 mins ago*/}
                    {/*                    </Text>*/}
                    {/*                </HStack>*/}

                    {/*                <Text mt={"0 !important"} fontSize={14}>*/}
                    {/*                    Can you check who paid for this?*/}
                    {/*                </Text>*/}
                    {/*            </Stack>*/}
                    {/*        </HStack>*/}
                    {/*    </Stack>*/}
                    {/*</Stack>*/}

                    {/*<InputGroup>*/}
                    {/*    <Input*/}
                    {/*     placeholder={'Add comment'}*/}
                    {/*    />*/}
                    {/*    <InputRightAddon*/}
                    {/*        cursor={"pointer"}*/}
                    {/*        // borderRadius={0}*/}
                    {/*        children={<IoMdSend color="var(--chakra-colors-teal-400)"/>}*/}
                    {/*    />*/}
                    {/*</InputGroup>*/}

                    <ButtonGroup>
                        <Spacer/>
                        <Button
                            onClick={() => dispatch(closeUploadHistoryViewModal())}>{t('common.actions.cancel')}</Button>
                        {/*<Button colorScheme="blue" bg={"teal.400"}>*/}
                        {/*    Apply*/}
                        {/*</Button>*/}
                    </ButtonGroup>
                </Stack>
            </ModalContent>
        </Modal>
    );
}
