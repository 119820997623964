import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Heading,
    Switch,
    Center,
    HStack,
    Spacer,
    Skeleton,
    Text, useColorModeValue,
} from '@chakra-ui/react'
import {useTranslation} from "react-i18next";
import {useGetEmailUploadAddressesListQuery} from "../../services/emailUploadAddressesService";
import {EmailUploadAddress} from "../../types";
import {useGetCurrentUserQuery} from "../../services/userService";
import {copyToast} from "../../slices/toastSlice";
import CopyToClipboard from 'react-copy-to-clipboard';
import {useAppDispatch} from "../../hooks/store";

export default function EmailUploadAddressesTable() {
    const {t} = useTranslation()
    const {data: emailsData, isLoading} = useGetEmailUploadAddressesListQuery()
    const {data: currentUser} = useGetCurrentUserQuery(undefined)
    const tealColor = useColorModeValue('teal.50', 'teal.900')
    const stripedColor = useColorModeValue('gray.50', 'gray.700')
    const dispatch = useAppDispatch();

    const renderEmailRow = (email: EmailUploadAddress, index: number) => {
        const isCurrentUser = email.user && email.user.id === currentUser?.id
        const bgColor = isCurrentUser ? tealColor : (index % 2 === 0 ? 'transparent' : stripedColor)

        return (
            <Tr
                key={email.id}
                bg={bgColor}
                sx={{
                    td: {textAlign: 'center', color: isCurrentUser ? 'teal.800' : 'gray.700'},
                }}
            >
                <CopyToClipboard
                    text={email.email}
                    onCopy={() => dispatch(copyToast("toast.mailCopy"))}
                >
                        <Td cursor="pointer">{email.email}</Td>
                </CopyToClipboard>
                <Td>{email.user ? `${email.user.first_name} ${email.user.last_name}` : '-'}</Td>
                <Td>
                    <Switch isChecked={email.active} isReadOnly/>
                </Td>
                {/*<Td>*/}
                {/*    {email.external ? (*/}
                {/*        <Badge colorScheme="blue">{t('emailUploadAddress.external')}</Badge>*/}
                {/*    ) : (*/}
                {/*        <Badge colorScheme="green">{t('emailUploadAddress.internal')}</Badge>*/}
                {/*    )}*/}
                {/*</Td>*/}
            </Tr>
        )
    }

    const globalEmails = emailsData?.filter(email => email.user === null) || []
    const userEmails = emailsData?.filter(email => email.user !== null) || []

    const tableContent = isLoading ? (
        <Tbody>
            {[...Array(3)].map((_, index) => (
                <Tr key={index}>
                    <Td><Skeleton height="20px"/></Td>
                    <Td><Skeleton height="20px"/></Td>
                    <Td><Skeleton height="20px"/></Td>
                    {/*<Td><Skeleton height="20px"/></Td>*/}
                </Tr>
            ))}
        </Tbody>
    ) : (
        <>
            <Tbody>
                <Tr>
                    <Td colSpan={3}>
                        <Heading as="h3" size="xs" my={2}>
                            {t('emailUploadAddress.globalTitle')}
                        </Heading>
                    </Td>
                </Tr>
                {globalEmails.length > 0 ? (
                    globalEmails.map((email, index) => renderEmailRow(email, index))
                ) : (
                    <Tr>
                        <Td colSpan={3}>
                            <Text textAlign="center" color="gray.500">
                                {t('emailUploadAddress.noGlobalEmails')}
                            </Text>
                        </Td>
                    </Tr>
                )}
            </Tbody>
            <Tbody>
                <Tr>
                    <Td colSpan={3}>
                        <Heading as="h3" size="xs" my={2}>
                            {t('emailUploadAddress.userTitle')}
                        </Heading>
                    </Td>
                </Tr>
                {userEmails.map((email, index) => renderEmailRow(email, index))}
            </Tbody>
        </>
    )

    return (
        <Box py={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center px={8} mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('emailUploadAddress.title')}</Heading>
                </HStack>
                <Spacer/>
            </Center>
            {/*todo late pagination and sort*/}
            <TableContainer w={"full"}>
                <Table variant="simple">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            <Th>{t('emailUploadAddress.user')}</Th>
                            <Th>{t('emailUploadAddress.address')}</Th>
                            <Th>{t('emailUploadAddress.active')}</Th>
                            {/*<Th>{t('emailUploadAddress.type')}</Th>*/}
                        </Tr>
                    </Thead>
                    {tableContent}
                </Table>
            </TableContainer>
        </Box>
    )
}
