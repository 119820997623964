import {
    ButtonGroup,
    Center, Container, FormLabel, Heading, HStack, IconButton,
    Spacer, useRadioGroup, VStack,
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {HiSortDescending} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTaskAssigneeFilter,
    selectTaskStatusFilter,
    setAssigneeFilter,
    setStatusFilter
} from "../../slices/taskFilterSlice";
import {isTaskSorted, showTaskSortModal} from "../../slices/taskSortModalSlice";
import RadioCard from "../RadioCard";

export default function TasksHeader({count = 0}) {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const status = useSelector(selectTaskStatusFilter);
    const assignee = useSelector(selectTaskAssigneeFilter);
    const isSorting = useSelector(isTaskSorted);

    const {
        getRootProps: getRootPropsStatus,
        getRadioProps: getRadioPropsStatus,
        value: valueStatus
    } = useRadioGroup({
        name: "status",
        defaultValue: status,
    });

    const {
        getRootProps: getRootPropsAssignee,
        getRadioProps: getRadioPropsAssignee,
        value: valueAssignee
    } = useRadioGroup({
        name: "assignee",
        defaultValue: assignee,
    });

    const groupStatus = getRootPropsStatus();
    const groupAssignee = getRootPropsAssignee();

    const optionsStatus = ["all", "new", "open", "done", "closed"];
    const optionsAssignee = ["all", "me"];

    useEffect(() => {
        if (valueStatus) {
            dispatch(setStatusFilter(valueStatus));
        }
    }, [valueStatus, dispatch]);

    useEffect(() => {
        if (valueAssignee) {
            dispatch(setAssigneeFilter(valueAssignee));
        }
    }, [valueAssignee, dispatch]);

    return (
        <Container maxW={"container.xl"} mb={3}>
            <Center w={"full"} pb={2}>
                {/* files header */}
                <Center gap={2}>
                    <Heading fontSize={24}>{t('tasks.task', {count: 2})}</Heading>
                    <Heading size={"md"} color={"gray.500"}>
                        {count}
                    </Heading>
                </Center>
                <Spacer/>
            </Center>
            <Center>
                <VStack me={4}>
                    <FormLabel alignSelf={'flex-start'} pb={0} mb={0}>{t('common.status')}</FormLabel>
                    <HStack {...groupStatus}
                            rounded={"2xl"}
                            bg={"white"}
                            border={"10px primary var(--chakra-colors-teal-200)"}
                    >
                        {optionsStatus.map((value) => {
                            const radio = getRadioPropsStatus({value});
                            return (
                                <RadioCard key={value} {...radio}>
                                    {t(`tasks.status.${value}`)}
                                </RadioCard>
                            );
                        })}
                    </HStack>
                </VStack>
                <VStack>
                    <FormLabel alignSelf={'flex-start'} pb={0} mb={0}>{t('common.assignee')}</FormLabel>
                    <HStack {...groupAssignee}
                            rounded={"2xl"}
                            bg={"white"}
                            border={"10px primary var(--chakra-colors-teal-200)"}
                    >
                        {optionsAssignee.map((value) => {
                            const radio = getRadioPropsAssignee({value});
                            return (
                                <RadioCard key={value} {...radio}>
                                    {t(`tasks.assignee.${value}`)}
                                </RadioCard>
                            );
                        })}
                    </HStack>
                </VStack>
                <Spacer/>
                <ButtonGroup sx={{svg: {fontSize: 20, color: "gray.500"}}}>
                    <IconButton
                        variant={"ghost"}
                        onClick={() => dispatch(showTaskSortModal())}
                        aria-label="Sort tasks"
                        icon={isSorting ? <HiSortDescending color="teal"/> : <HiSortDescending/>}
                    />
                    {/*<IconButton*/}
                    {/*    variant={"ghost"}*/}
                    {/*    onClick={() => dispatch(showFileFilterModal())}*/}
                    {/*    aria-label="Filter tasks"*/}
                    {/*    icon={isFiltering ? <HiFilter color="teal"/> : <BiFilterAlt/>}*/}
                    {/*/>*/}
                </ButtonGroup>
            </Center>
        </Container>
    );
}

TasksHeader.propTypes = {
    count: PropTypes.number.isRequired,
};

